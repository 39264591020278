<template>
  <q-input v-model="targetUrl" :label="service" filled clearable>
    <template #prepend>
      <q-select
        v-model="service"
        option-value="url"
        option-label="name"
        emit-value
        map-options
        :options="services"
      >
      </q-select>
    </template>

    <template #append>
      <q-btn icon="open_in_new" flat fab @click="openInNewWindow" />
      <q-btn icon="refresh" flat fab @click="reloadIframe" />
    </template>
  </q-input>
  <iframe
    ref="iframe"
    :src="src"
    frameborder="0"
    allowfullscreen
    webkitallowfullscreen
    mozallowfullscreen
    width="100%"
    border="0"
  ></iframe>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  onUnmounted,
  nextTick,
  computed,
  watchEffect,
} from "vue";

import serviceSources from "./services4";

export default defineComponent({
  name: "App",
  setup() {
    let services = reactive(serviceSources);

    let service = ref(localStorage.getItem("service") || services[0].url);
    watchEffect(() => {
      localStorage.setItem("service", service.value);
    });

    let targetUrl = ref(localStorage.getItem("targetUrl") || "");
    watchEffect(() => {
      console.log("更新 targetUrl", targetUrl.value);
      localStorage.setItem("targetUrl", targetUrl.value);
    });

    const showIframe = ref(true);
    let src = computed(() => {
      console.log(targetUrl.value);
      return showIframe.value ? service.value + targetUrl.value : "";
    });
    const openInNewWindow = () => window.open(src.value, "_blank");

    // 计算iframe的显示高度
    const iframe = ref(null);
    const resizeIframeSize = () => {
      let { clientHeight } = document.documentElement;
      let rect = iframe.value.getBoundingClientRect();
      iframe.value.height = clientHeight - rect.top;
    };
    const reloadIframe = () => {
      showIframe.value = false;
      nextTick(() => (showIframe.value = true));
    };
    onMounted(() => {
      resizeIframeSize();
      window.addEventListener("resize", resizeIframeSize);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", resizeIframeSize);
    });

    return {
      service,
      targetUrl,
      src,
      services,
      iframe,
      reloadIframe,
      openInNewWindow,
    };
  },
});
</script>

<style></style>
