<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-toolbar-title>
          Video Parser
        </q-toolbar-title>

        <div>v1.0</div>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <VideoParse />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import VideoParse from "./components/VideoParse/index.vue";

export default {
  name: "LayoutDefault",

  components: {
    VideoParse,
  },

  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>
