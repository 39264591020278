export default [
    { "name": "综合/B站", "url": "https://jx.bozrc.com:4433/player/?url=", "showType": 3 },
    { "name": "纯净/B站", "url": "https://m1907.top/?jx=", "showType": 3 },
    { "name": "B站解析1", "url": "https://vip.parwix.com:4433/player/?url=" },
    { "name": "爱豆", "url": "https://jx.aidouer.net/?url=", "showType": 1 },
    { "name": "虾米", "url": "https://jx.xmflv.com/?url=", "showType": 1 },
    { "name": "OK解析", "url": "https://okjx.cc/?url=", "showType": 3 },
    { "name": "夜幕", "url": "https://www.yemu.xyz/?url=", "showType": 3 },
    { "name": "M3U8.TV", "url": "https://jx.m3u8.tv/jiexi/?url=", "showType": 3 },
    { "name": "人人迷", "url": "https://jx.blbo.cc:4433/?url=", "showType": 3 },
    { "name": "全民", "url": "https://jx.blbo.cc:4433/?url=", "showType": 3 },
    { "name": "七哥", "url": "https://jx.mmkv.cn/tv.php?url=", "showType": 3 },
    { "name": "冰豆", "url": "https://api.qianqi.net/vip/?url=", "showType": 3 },
    { "name": "迪奥", "url": "https://123.1dior.cn/?url=", "showType": 1 },
    { "name": "CK", "url": "https://www.ckplayer.vip/jiexi/?url=", "showType": 1 },
    { "name": "ckmov", "url": "https://www.ckmov.vip/api.php?url=", "showType": 1 },
    { "name": "playerjy/B站", "url": "https://jx.playerjy.com/?url=", "showType": 3 },
    { "name": "ccyjjd", "url": "https://ckmov.ccyjjd.com/ckmov/?url=", "showType": 1 },
    { "name": "诺诺", "url": "https://www.ckmov.com/?url=", "showType": 1 },
    { "name": "H8", "url": "https://www.h8jx.com/jiexi.php?url=", "showType": 1 },
    { "name": "BL", "url": "https://vip.bljiex.com/?v=", "showType": 1 },
    { "name": "解析la", "url": "https://api.jiexi.la/?url=", "showType": 1 },
    { "name": "MUTV", "url": "https://jiexi.janan.net/jiexi/?url=", "showType": 1 },
    { "name": "MAO", "url": "https://www.mtosz.com/m3u8.php?url=", "showType": 1 },
    { "name": "老板", "url": "https://vip.laobandq.com/jiexi.php?url=", "showType": 1 },
    { "name": "盘古", "url": "https://www.pangujiexi.cc/jiexi.php?url=", "showType": 1 },
    { "name": "盖世", "url": "https://www.gai4.com/?url=", "showType": 1 },
    { "name": "小蒋", "url": "https://www.kpezp.cn/jlexi.php?url=", "showType": 1 },
    { "name": "YiTV", "url": "https://jiexi.us/?url=", "showType": 1 },
    { "name": "星空", "url": "http://60jx.com/?url=", "showType": 1 },
    { "name": "0523", "url": "https://go.yh0523.cn/y.cy?url=", "showType": 1 },
    { "name": "17云", "url": "https://www.1717yun.com/jx/ty.php?url=", "showType": 1 },
    { "name": "4K", "url": "https://jx.4kdv.com/?url=", "showType": 1 },
    { "name": "8090", "url": "https://www.8090g.cn/?url=", "showType": 1 },
    { "name": "诺讯", "url": "https://www.nxflv.com/?url=", "showType": 1 },
    { "name": "听乐", "url": "https://jx.dj6u.com/?url=", "showType": 1 },
    { "name": "180", "url": "https://jx.000180.top/jx/?url=", "showType": 1 },
    { "name": "无名", "url": "https://www.administratorw.com/video.php?url=", "showType": 1 },
    { "name": "听乐(B站)", "url": "https://jx.dj6u.com/?url=", "showType": 2 },
]